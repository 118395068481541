<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="statDateDay"
                    label="统计时间"
                >
                    <el-date-picker
                        :picker-options="$utils.CommonConfig.pickerOptions"
                        v-model="queryFormModel.statDateDay"
                        type="daterange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>
                <el-form-item
                    prop="mobile"
                    label="手机号"
                >
                    <el-input
                        v-model="queryFormModel.mobile"
                        placeholder="请输入手机号"
                    />
                </el-form-item>
                <el-form-item
                    prop="buyerLevelId"
                    label="会员等级"
                >
                    <el-select
                        v-model="queryFormModel.buyerLevelId"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in buyerLevelList"
                            :key="item.id"
                            :label="item.levelName"
                            :value="item.id + ''"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="distributorLevelId"
                    label="分销等级"
                >
                    <el-select
                        v-model="queryFormModel.distributorLevelId"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in distributorLevelList"
                            :key="item.id"
                            :label="item.levelName"
                            :value="item.id + ''"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="orderByType"
                    label="排序"
                >
                    <el-select
                        v-model="queryFormModel.orderByType"
                        placeholder="请选择"
                    >
                        <el-option
                            label="按客单价排序"
                            value="3"
                        />
                        <el-option
                            label="按订单金额排序"
                            value="2"
                        />
                        <el-option
                            label="按订单数量排序"
                            value="1"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="minCustomerPrice"
                    label="客单价大于"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        :precision="2"
                        v-model.number="queryFormModel.minCustomerPrice"
                    />
                </el-form-item>
                <el-form-item
                    prop="minOrderAmount"
                    label="订单总金额大于"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        :precision="2"
                        v-model.number="queryFormModel.minOrderAmount"
                    />
                </el-form-item>
                <el-form-item
                    prop="minOrderCount"
                    label="订单数大于"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        :precision="2"
                        v-model.number="queryFormModel.minOrderCount"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    type="index"
                    width="50"
                />
                <el-table-column
                    prop="mobile"
                    label="手机号"
                    min-width="100"
                />
                <el-table-column
                    label="会员信息"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        {{ scope.row.nickName }}({{ scope.row.buyerLevelName }})
                    </template>
                </el-table-column>
                <el-table-column
                    label="分销信息"
                    min-width="100"
                >
                    <template
                        slot-scope="scope"
                    >
                        {{ scope.row.distributorName }}<span v-if="scope.row.distributorLevelName">({{ scope.row.distributorLevelName }})</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="orderNum"
                    label="订单数量"
                    min-width="100"
                />
                <el-table-column
                    prop="orderAmount"
                    label="订单金额"
                    min-width="100"
                />
                <el-table-column
                    prop="customerPrice"
                    label="客单价"
                    min-width="100"
                />
                <el-table-column
                    prop="purseBalance"
                    label="零钱余额"
                    min-width="100"
                />
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixin';

export default {
    name: 'BuyerOrderStat',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                statDateDay: '',
                orderByType: '3',
                minCustomerPrice: undefined,
                minOrderAmount: undefined,
                minOrderCount: undefined,
                buyerLevelId: '',
                distributorLevelId: '',
                mobile: '',
            },
            // 表格数据
            tableData: [],
            buyerLevelList: [],
            distributorLevelList: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Stat.BuyerStat.buyerOrderStatList(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        /* -- 事件 --*/
        initLevelList() {
            this.$api.Mb.BuyerLevel.select().then(json => {
                const res = json.data.data;
                this.buyerLevelList = res;
            });
            this.$api.Mb.DistributorLevel.select().then(json => {
                const res = json.data.data;
                this.distributorLevelList = res;
            });
        },
    },
    created() {
        this.queryFormModel.statDateDay = [this.$utils.Common.getSysDayDate(), this.$utils.Common.getSysDayDate()];
        this.initLevelList();
    },
};
</script>

<style lang="scss" scoped>
</style>
